import moment from 'moment';
import { isPrecedentUser } from '../../../common-roles';

export const DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE = {
    path: 'demandDetails.demandResponseDueDate',
    label: 'Demand response fixed due date',
    dataType: 'date',
    fieldType: 'date',
    required: false,
    validation: (value, userData) => {
        const isPrecedent = isPrecedentUser(userData);
        if (!value) return 'Required';
        if (!moment(value, 'YYYY-MM-DD', true).isValid()) return 'Invalid date';
        if (!isPrecedent && moment(value).isBefore(moment())) return 'Due date cannot be in the past';
        if (!isPrecedent && moment(value).isAfter(moment().add(50, 'years'))) return 'Due date must be more recent';
        return null;
    }
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE = {
    path: 'demandDetails.demandResponseRelativeDueDate',
    label: 'Relative days to respond',
    dataType: 'number',
    fieldType: 'number',
    required: false,
    validation: undefined
}
