import React from 'react';
import { TextField } from 'mui-rff';
import { InfoOutlined } from '@mui/icons-material';
import { MAX_DAYS_RELATIVE_DUE_DATE } from '../../../common';
import { Tooltip } from '@mui/material';
import { DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE, DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE } from '../Schemas/DemandDetails';
import { getCurrentDate } from '../../../common';

export const DemandResponseDueDateField = ({ form, userData }) => {
    return (
        <TextField
            name={DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.path}
            fullWidth
            required={DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.required}
            inputProps={{ min: getCurrentDate() }}
            label={DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.label}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
                form.change(DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.path, e.target.value)
                form.change(DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.path, '')
            }}
            InputProps={{
                endAdornment:
                    <Tooltip title="Using a fixed due date will set the due date to a specific date." arrow placement="top">
                        <div style={{ marginTop: '8px' }}>
                            <InfoOutlined color="action" />
                        </div>
                    </Tooltip>
            }}
            fieldProps={{ validate: (value) => DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.validation(value, userData) }}
        />
    )
}

export const DemandResponseRelativeDueDateField = ({ form }) => {
    return (
        <TextField
            required={DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.required}
            name={DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.path}
            label={DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.label}
            type="number"
            fullWidth
            onChange={(e) => {
                //clip the input values to the range (1, MAX_DAYS_RELATIVE_DUE_DATE)
                e.target.value = Math.min(Math.max(parseInt(e.target.value), 1), MAX_DAYS_RELATIVE_DUE_DATE);
                form.change(DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.path, e.target.value);
                form.change(DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.path, '');
            }}
            onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Filter out non-integer values
            }}
            fieldProps={{ validate: DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.validation }}
        />
    )
}
