import { isValidPhoneNumber, isValidEmail } from '../../ValidationHelpers';

// recipientCarrier?.carrierCommonName,
export const RECIPIENT_CARRIER_COMMON_NAME = {
    path: 'autoCompletes.carrier',
    label: 'Carrier',
    dataType: 'string',
    fieldType: 'autocomplete',
    required: true,
    validation: (value) => !value ? 'Carrier is required' : null
}

// recipientCarrier?.contactInfo?.faxNumber,
export const RECIPIENT_CARRIER_FAX_NUMBER = {
    path: 'recipientCarrier.contactInfo.faxNumber',
    label: 'Carrier fax number',
    dataType: 'string',
    fieldType: 'text',
    required: false,
    validation: value => value && !isValidPhoneNumber(value) ? 'Invalid fax number' : null
}

// recipientCarrier?.contactInfo?.emailAddress,
export const RECIPIENT_CARRIER_EMAIL_ADDRESS = {
    path: 'recipientCarrier.contactInfo.emailAddress',
    label: 'Carrier email',
    dataType: 'string',
    fieldType: 'text',
    required: false,
    validation: value => value && !isValidEmail(value) ? 'Invalid email' : null
}

// recipientCarrier?.contactInfo?.phoneNumber,
export const RECIPIENT_CARRIER_PHONE_NUMBER = {
    path: 'recipientCarrier.contactInfo.phoneNumber',
    label: 'Carrier phone',
    dataType: 'string',
    fieldType: 'text',
    required: false,
    validation: value => value && !isValidPhoneNumber(value) ? 'Invalid phone number' : null
}
