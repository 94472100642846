import React from 'react';
import { TextField, Autocomplete } from 'mui-rff';
import { RECIPIENT_CARRIER_COMMON_NAME, RECIPIENT_CARRIER_FAX_NUMBER, RECIPIENT_CARRIER_EMAIL_ADDRESS, RECIPIENT_CARRIER_PHONE_NUMBER } from '../Schemas/RecipientCarrier';
import { getSingleSelectAutoCompleteFilteredOptions } from '../Helpers';
import { formatPhoneNumber } from '../../../common';

// recipientCarrier?.contactInfo?.phoneNumber
export const RecipientCarrierPhoneNumber = () => {
    return (
        <TextField
            name={RECIPIENT_CARRIER_PHONE_NUMBER.path}
            required={RECIPIENT_CARRIER_PHONE_NUMBER.required}
            label={RECIPIENT_CARRIER_PHONE_NUMBER.label}
            type='tel'
            autoComplete='off'
            onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
        />
    )
}

// recipientCarrier?.contactInfo?.faxNumber
export const RecipientCarrierFaxNumber = () => {
    return (
        <TextField
            fullWidth
            required={RECIPIENT_CARRIER_FAX_NUMBER.required}
            label={RECIPIENT_CARRIER_FAX_NUMBER.label}
            name={RECIPIENT_CARRIER_FAX_NUMBER.path}
            type='tel'
            autoComplete='off'
            onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
        />
    )
}

// recipientCarrier?.contactInfo?.emailAddress
export const RecipientCarrierEmailAddress = () => {
    return (
        <TextField
            fullWidth
            required={RECIPIENT_CARRIER_EMAIL_ADDRESS.required}
            label={RECIPIENT_CARRIER_EMAIL_ADDRESS.label}
            name={RECIPIENT_CARRIER_EMAIL_ADDRESS.path}
            type='email'
            autoComplete='off'
        />
    )
}

// recipientCarrier?.carrierCommonName
export const RecipientCarrierCommonName = ({ form, carrier_auto_complete_data }) => {
    return (
        <Autocomplete
            name={RECIPIENT_CARRIER_COMMON_NAME.path}
            required={RECIPIENT_CARRIER_COMMON_NAME.required}
            label={RECIPIENT_CARRIER_COMMON_NAME.label}
            freeSolo
            fullWidth
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            blurOnSelect
            options={carrier_auto_complete_data}
            filterOptions={(options, params) => {
                const filtered = getSingleSelectAutoCompleteFilteredOptions(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        value: { carrierCommonName: inputValue },
                        label: `Add "${inputValue}"`,
                    });
                }
                return filtered;
            }}
            isOptionEqualToValue={(option, value) => option?.label === value}
            fieldProps={{
                format: (value) => value?.value?.carrierCommonName,
                validate: RECIPIENT_CARRIER_COMMON_NAME.validation
            }}
            onChange={(e, selection) => {
                form.change('recipientCarrier', selection?.value || {
                    carrierCommonName: '',
                    carrierCustomerAliasId: '',
                    contactInfo: {
                        address: {
                            city: '',
                            state: '',
                            street1: '',
                            street2: '',
                            zipCode: ''
                        },
                        emailAddress: '',
                        faxNumber: '',
                        phoneNumber: ''
                    }
                })
            }}
        />
    )
}