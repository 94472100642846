import { Paper, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import Title from '../../CommonComponents/Title';
import { useSelector } from 'react-redux';
import { CheckCircle, Error, RadioButtonUnchecked } from '@mui/icons-material';
import { META_DATA_TEMPLATE_ID } from '../../FormFieldsAndSchemas/Demand/Schemas/MetaData';
import { SENDING_FIRM_CASE_NUMBER } from '../../FormFieldsAndSchemas/Demand/Schemas/SendingFirm';
import {
  CLAIM_INFO_CLAIMANT_FIRSTNAME,
  CLAIM_INFO_INJURED_PARTY,
  CLAIM_INFO_INSURED_FIRST_NAME,
  CLAIM_INFO_TORTFEASOR,
  CLAIM_INFO_DATE_OF_LOSS,
  CLAIM_INFO_LOSS_STATE,
} from '../../FormFieldsAndSchemas/Demand/Schemas/ClaimInfo';
import { RECIPIENT_CARRIER_COMMON_NAME } from '../../FormFieldsAndSchemas/Demand/Schemas/RecipientCarrier';
import { AUTOCOMPLETES_PRIMARY_CONTACT, AUTOCOMPLETES_APPROVER } from '../../document-fields';

const REQUIRED_FIELDS_FOR_TOOLTIP = [
    META_DATA_TEMPLATE_ID,
    SENDING_FIRM_CASE_NUMBER,
    CLAIM_INFO_CLAIMANT_FIRSTNAME,
    CLAIM_INFO_INJURED_PARTY,
    CLAIM_INFO_INSURED_FIRST_NAME,
    CLAIM_INFO_TORTFEASOR,
    CLAIM_INFO_DATE_OF_LOSS,
    CLAIM_INFO_LOSS_STATE,
    RECIPIENT_CARRIER_COMMON_NAME,
    AUTOCOMPLETES_PRIMARY_CONTACT,
    AUTOCOMPLETES_APPROVER,
];

const findErrorPath = (obj, errorString, path = "") => {
    const result = [];

    // Iterate through all properties of the object
    for (const key in obj) {
        // Check if the property is an object and recurse into it
        if (typeof obj[key] === "object" && obj[key] !== null) {
            findErrorPath(obj[key], errorString, `${path}${key}.`).forEach(subPath => {
                result.push(subPath);
            });
        } else if (obj[key] && errorString !== "") {
            // If the property contains the error string, add its path to the result
            result.push(`${path}${key}`);
        }
    }

    return result;
};

const RequiredFieldsTooltip = ({ errors, touched, values, children, valid, hasUploadErrors }) => {
    const { documentUploaderList } = useSelector((state) => state.DemandDom);
    const haveFilesUploaded = documentUploaderList.length > 0;
    const errorArray = findErrorPath(errors);
    const hasDocumentError = documentUploaderList.some((file) => file.error);

    const documentsIcon = () => {
        if (!haveFilesUploaded) {
            return <RadioButtonUnchecked color="disabled" />
        } else if (haveFilesUploaded && !hasDocumentError) {
            return <CheckCircle color="success" />
        } else if (hasDocumentError) {
            return <Error color="error" />
        }
    };

    const getIcon = (fieldPath) => {
        if (errorArray.includes(fieldPath) && !touched[fieldPath]) {
            //default unchecked display
            return (
                <RadioButtonUnchecked color="disabled" />
            );
        } else if (errorArray.includes(fieldPath) && touched[fieldPath]) {
            //error display
            return (
                <Error color="error" />   
            );
        } else {
            //successfully completed the field
            return (
                <CheckCircle color="success" />    
            );
        }
    };
    
    return (
        <Tooltip
            title={
                valid && haveFilesUploaded && !hasUploadErrors ? ''
                    :
                    <Paper
                        elevation={8}
                        sx={{
                            px: 2,
                            py: 1
                        }}
                    >
                        <Stack
                            spacing={1}
                        >
                            <Title>Required fields</Title>
                            {/* iterate through required fields */}
                            {
                                REQUIRED_FIELDS_FOR_TOOLTIP.map((field) => {

                                    //conditionally exclude these required fields from the tooltip
                                    if (field.path === CLAIM_INFO_INJURED_PARTY && values.claimInfo.clientIsInjuredParty === true) {
                                        return
                                    }
                                    if (field.path === CLAIM_INFO_TORTFEASOR && values.claimInfo.insuredIsTortfeasor === true) {
                                        return
                                    }
                                    if (field.path === AUTOCOMPLETES_APPROVER && values.mutators.attorneyIsPrimaryContact === true) {
                                        return
                                    }

                                    return (
                                        <Stack
                                            key={field.path}
                                            direction="row"
                                            spacing={1}
                                        >
                                            {getIcon(field.path)}
                                            <Typography variant="body1">
                                                {field.label}
                                            </Typography>
                                        </Stack>
                                    )
                                })
                            }
                            {/* add documents to the bottom of the required fields list */}
                            <Stack
                                direction={"row"}
                                spacing={1}
                                key="required-documents"
                            >
                                {documentsIcon()}
                                <Typography
                                    variant="body1"
                                >
                                    Documents
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
            }
            placement="right-start"
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [-6, -16],
                            },
                        },
                    ],
                },
            }}
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: 'transparent',
                    },
                },
            }}
        >
            <span>
                {children}
            </span>
        </Tooltip>
    );
};

export default RequiredFieldsTooltip;
