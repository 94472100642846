import React from 'react';
import { TextField } from 'mui-rff';
import { cloneDeep, merge } from 'lodash';
import { Grid, MenuItem, Stack, Typography } from '@mui/material';
import { META_DATA_TEMPLATE_ID } from '../Schemas/MetaData';

// 'metadata.demandTemplateId'
export const DemandTemplateField = ({ form, values, availableTemplates }) => {

    return (
        <Stack>
            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Select template</Typography>
            <Grid xs={12} sx={{ p: 0 }} >

                <TextField
                    label={META_DATA_TEMPLATE_ID.label}
                    name={META_DATA_TEMPLATE_ID.path}
                    required={META_DATA_TEMPLATE_ID.required}
                    select
                    fullWidth
                    fieldProps={{ validate: META_DATA_TEMPLATE_ID.validation }}
                    onChange={(e) => {
                        const templateId = e.target.value;
                        form.change(META_DATA_TEMPLATE_ID.path, templateId);
                        const currentTemplate = availableTemplates.find(at => at.templateId === templateId);
                        const currentTemplateDemandConfig = currentTemplate?.defaultDemandConfig || {};

                        for (const key in currentTemplateDemandConfig) {
                            const currentCorrespondingValues = cloneDeep(values[key]);
                            const defaultCorrespondingValues = cloneDeep(currentTemplateDemandConfig[key]);
                            const newCorrespondingValues = merge(currentCorrespondingValues, defaultCorrespondingValues);
                            form.change(key, newCorrespondingValues);
                        }
                    }}
                >
                    {availableTemplates.map((option) => {
                        return (

                            <MenuItem key={option.templateId} value={option.templateId} >
                                {option.templateName}
                            </MenuItem>
                        )
                    })}
                </TextField>

            </Grid>
        </Stack>
    )
}